@font-face {
  font-family: TangXianBinSong;
  src: url("../public/font/TangXianBinSong-2.otf");
}
.home {
  overflow: hidden;
  position: relative;
  padding-bottom: 80px;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.homebg {
  width: 100vw;
  /* margin-top: 0px; */
}
.flex {
  display: flex;
}
.ant-carousel .slick-dots {
  width: 30px;
  padding: 0 48%;
}
.ant-carousel .slick-dots li.slick-active button {
  background-color: #d4fd48; /* 你想要的颜色 */
  width: 16px;
}
.ant-carousel .slick-dots li button {
  width: 6px; /* 未激活的标点颜色 */
}
:where(.css-dev-only-do-not-override-v57az).ant-input-number-affix-wrapper
  input.ant-input-number-input {
  color: #fff;
}

:where(.css-dev-only-do-not-override-1okl62o).ant-progress .ant-progress-inner {
  background: rgba(107, 107, 107, 1);
}
.ant-input-number input::-webkit-input-placeholder {
  color: #999 !important; /* 你想要的颜色 */
}

.ant-input-number input:-moz-placeholder {
  color: #999 !important; /* 你想要的颜色 */
}

.ant-input-number input::-moz-placeholder {
  color: #999 !important; /* 你想要的颜色 */
}

.ant-input-number input:-ms-input-placeholder {
  color: #999 !important; /* 你想要的颜色 */
}
body {
  zoom: 1;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  touch-action: pan-y;
  background-color: #000;

  color: #fff;
}
img {
  -webkit-touch-callout: none; /* iOS Safari禁止长按保存图片 */
  pointer-events: none;
  zoom: 1;
}
.ant-select-selector {
  background-color: transparent !important;
  border: none !important;
}
.ant-select-selector:focus {
  border: none !important;
}
.ant-select-arrow {
  color: #fff !important; /* 设置箭头颜色 */
}
a,
a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
}

.ant-progress-text {
  margin-left: 4px !important;
  color: #000 !important;
  font-family: zihunlaozhaopai_trial;
  font-size: 10px;
  font-weight: 400;
  line-height: 13px;
  text-align: left;
}
.homebtnbuy {
  font-family: zixiaohunxingjililiangti;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  color: #3b0bc7;
  background: #fff;
  border: 0.5px solid #ffffff1f;
  height: 48px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}
.homebtnbuy[disabled] {
  background-color: rgb(86, 86, 226);
  color: #000;
}

.white {
  color: #fff;
}
.red {
  color: red;
}
.w-full {
  width: 100%;
}

*,
::before,
::after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}
.text-right {
  text-align: right;
}
.px-16 {
  padding: 0 16px;
}
.z-2 {
  z-index: 2;
}
