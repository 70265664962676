.w100 {
  width: 100%;
  margin-top: 10px;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.ab {
  position: absolute;
  top: 46.2%;
  left: 4.2%;
  width: 92%;
}

.flex_100 {
  display: flex;
  width: 100%;

  height: 48px;
  gap: 15px;
}
.gap20 {
  display: grid;
  gap: 14px;
  width: 100%;
  padding: 0 16px;
}
.font1 {
  font-family: zihunlaozhaopai_trial;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.2px;
  color: rgba(255, 255, 255, 0.6);
}
.font2 {
  font-family: zihunlaozhaopai_trial;
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  margin-right: 4px;
  color: rgba(255, 255, 255) !important;
}
.font3 {
  font-family: zihunlaozhaopai_trial;
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  margin-left: 16px;
  color: rgba(212, 253, 72, 1);
  display: flex;
  align-items: center;
}
.font4 {
  font-family: zihunlaozhaopai_trial;
  font-size: 12px;
  font-weight: 400;
  line-height: 15.6px;
  text-align: left;
  color: rgba(255, 255, 255, 0.4);
}
.g5 {
  display: grid;
  flex: 1;
  width: 50%;
  height: 43px;
  gap: 4px;
}
 
.g5r {
  display: flex;
  align-items: center;
  justify-content: right;

  flex: 1;
  width: 50%;
  height: 63px;
  margin-right: 6px;
}
.h43 {
  height: 43px;
}
.g5h {
  display: grid;
  flex: 1;
  width: 50%;
  height: 63px;
  gap: 4px;
}
.incomebtn {
  background: linear-gradient(90deg, #d4fd48 0%, #ffe800 100%);
  border: 0.5px solid rgba(255, 255, 255, 0.12);
  border-radius: 8px;
  width: 80px;
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: zihunlaozhaopai_trial;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: center;
  color: #000;
}
.incline {
  width: 98%;
  height: 1px;
  background: rgba(255, 255, 255, 0.08);
}
.ml1 {
  margin-left: -12px;
}
