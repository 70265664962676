.w_100 {
  width: 100%;
}
.center {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mintb {
  position: absolute;
  top: 62.6%;
  width: 76%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.dapptop{
  position: absolute;
  top: 50%;
  display: flex;
  align-items: center;
  left: 16px;
  margin-top: -76px;
  font-family: zihunlaozhaopai_trial;
font-size: 18px;
font-weight: 400;
line-height: 21.6px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;

}
.g24 {
  display: grid;
  gap: 24px;
  width: 100%;
  margin-bottom: 25px;
}
.g4 {
  display: grid;
  gap: 4pxx;
  text-align: right;
}
.inp {
  background: rgba(255, 255, 255, 0.08);
  border: 1px solid rgba(255, 255, 255, 1);
  height: 48px;
  padding: 0 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
}
.inp1 {
  background: rgba(255, 255, 255, 0.04);
  border: 1px solid rgba(255, 255, 255, 0.12);
  height: 48px;
  padding: 0 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  input {
    height: 48px;
    background-color: transparent;
    border: none; /* 移除边框 */
    outline: none;
    text-align: right;
    color: #fff;
  }

  input:focus {
    border-color: transparent;
    border-radius: 12px;
  }
  input::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.4);
  }
  input:-moz-placeholder {
    color: #999;
  }
  input::-moz-placeholder {
    color: #999;
  }
  input:-ms-input-placeholder {
    color: #999;
  }
}

.font1 {
  font-family: zihunlaozhaopai_trial;
  font-size: 12px;
  font-weight: 400;
  line-height: 15.6px;
  text-align: right;
  margin-top: 4px;
}
