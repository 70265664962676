.top {
  display: flex;
  position: fixed;
  top: 0;
  height: 80px;

  width: 100%;

  z-index: 999;
  align-items: center;
  justify-content: space-between;
  /* background-color: rgb(15, 152, 226); */
  /* background-color: rgb(49, 52, 252, 0.8); */
}
.black {
  background-color: black;
}
.d1 {
  font-family: Notable;
  font-size: 24px;
  font-weight: 400;
  line-height: 31.2px;
  text-align: center;
  margin-left: 16px;
}
.d0 {
  display: flex;

  margin-right: 16px;
}
.d1side {
  height: 24pt;
  line-height: 20pt;
  font-size: 20pt;
}
.d2 {
  margin-left: 10px;
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
}
.d3 {
  width: 80px;
}
.d0 {
  display: flex;
  align-items: center;
}
.img {
  width: 20px;
  height: 20px;
}
.topside {
  margin-left: 16px;
  margin-bottom: 50px;
  color: white;
  font-size: 26pt;
  margin-top: -20px;
}
.bt0 {
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  padding: 8px 12px;
  border-radius: 120px;
  box-shadow: inset 0 0 8px 0 #6158ff;
  border: 1px solid #706da5;
  background: linear-gradient(0deg, #100842, #100842),
    linear-gradient(0deg, #706da5, #706da5);
}

.bt2in1 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: zihunlaozhaopai_trial;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: center;
}

.img2 {
  width: 20px;
  height: 20px;
  margin-right: 5.5px;
}

.showlug {
  position: absolute;
  width: 100px;
  height: 96px;
  background: rgb(47, 43, 47);

  right: 16px;
  border-radius: 8px;
  color: #fff;
  font-size: 12px;
}
.showlug0 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50%;
}
.showlug1 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50%;
  opacity: 0.6;
}
.burn {
  background-color: #590ddc;
  width: 40px;
  height: 40px;
  position: absolute;
  right: 12px;
  /* margin-right: -30px; */
  filter: blur(30px);
  z-index: -1;
}
.language {
  width: 33px;
  height: 33px;
  margin-left: 4px;
  border-radius: 40px;
  border: 1px solid #fff;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
}
