.bu1 {
  display: flex;
  width: 100%;
  margin: 14px 16px 0 16px;
  background-color: rgb(12 0 51);
  border-radius: 120px;
  /* backdrop-filter: blur(10px); */
  border: 1px solid rgb(46 35 114);
  height: 57px;
  align-items: center;
}
.bu {
  /* Union */
  display: flex;
  position: fixed;
  width: 100%;
  height: 88.5px;
  left: 0;
  bottom: 0;
  z-index: 2;
}

.bu2 {
  width: 50%;
  display: flex;
}
.bu3 {
  color: white;
  display: flex;
  flex: 1;
  width: 50%;
  align-items: center;
  justify-content: center;
  font-family: zihunlaozhaopai_trial;
  font-size: 11px;
  font-weight: 400;
  line-height: 13.2px;
  text-align: center;
}
.bumg1 {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 28px;
}
.bumg0 {
  display: flex;
  justify-content: center;
  align-items: center;

  opacity: 0.6;

  height: 28px;
}
.bufont0 {
  color: #fff;
  font-size: 12px;
}
.bufont {
  color: #000;
}
.butimg {
  width: 24pt;
  height: 24pt;
}
@keyframes downAnimation {
  0% {
    top: 0;
  }

  100% {
    top: 100px;
  }
}
.showmsg {
  animation-name: downAnimation;
  animation-duration: 1s;
  display: flex;
  position: fixed;
  justify-content: center;
  width: 100%;
  height: 48px;
  top: 100px;
  z-index: 10;
}
.showmsg1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  background-color: #3d4652;
  font-size: 12px;
  line-height: 16px;
  color: #fff;
  border-radius: 8px;
}
.op5 {
  opacity: 0.5;
}
.circle {
  background-color: rgb(104 34 227);
  width: 70px;
  height: 65px;
  border-radius: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 40px;
    height: 40px;
  }
}
