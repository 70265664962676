.mintbg {
  width: 100%;
  margin-top: -120px;
}
.minttopbg {
  position: absolute;
  width: 86%;
  top: 28%;
  left: 50%;
  height: 183px;
  padding: 0 16px;
  display: grid;
  gap: 24px;
  transform: translate(-50%, -56%);
}
.mintfont0 {
  font-family: zihunlaozhaopai_trial;
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
}
.mintInput {
  font-family: zihunlaozhaopai_trial;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  line-height: 16.8px;
  text-align: left;
  color: #fff;
  background: rgba(255, 255, 255, 0.08);
  border: 1px solid rgba(255, 255, 255, 1);
  height: 41px;
  padding: 0 12px;
  border-radius: 12px;
}
.mintInput input::placeholder {
  color: #d4fd48;
}
.font2 {
  font-family: zihunlaozhaopai_trial;
  font-size: 12px;
  font-weight: 400;
  line-height: 15.6px;
  text-align: right;
}
.g4 {
  display: grid;
  gap: 4px;
}
.mintbodybg {
  position: absolute;
  top: 66.6%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.mintbodyt {
  display: flex;

  margin-bottom: 16px;
}
.mintbodybar {
  display: flex;
  height: 200px;
  font-family: zihunlaozhaopai_trial;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.8px;
  text-align: left;

  gap: 20px;
}
.font {
  font-family: zihunlaozhaopai_trial;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.2px;
  text-align: left;
}
.between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mintbody {
  top: 18.6%;
  left: 50%;
  width: 86%;

  position: absolute;
  transform: translateX(-50%);
  display: grid;
  gap: 20px;
}
.btn {
  width: 80px;
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 0.5px solid rgba(255, 255, 255, 0.12);
  color: #000;
  background: linear-gradient(90deg, #d4fd48 0%, #ffe800 100%);
}
.g4 {
  display: grid;
  gap: 4px;
}
