.topbar {
  display: grid;
  gap: 24px;
  position: relative;
  padding: 16px;
  margin: 16px;
  background-image: (
      95.31deg,
      rgba(0, 0, 0, 0.8) -3.76%,
      rgba(0, 0, 0, 0.7) 98.27%
    ),
    linear-gradient(102.48deg, #6854e8 49.73%, rgba(173, 172, 255, 0.3) 116.4%);
  border: 1px solid rgb(104 84 232/1);
  border-radius: 12px;
  font-family: zihunlaozhaopai_trial;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.2px;
  text-align: left;
  margin-top: -100px;
  z-index: 2;
}
.burn0 {
  width: 100vw;
  height: 150px;
  right: 0;
  position: absolute;
  margin-top: -140px;
  background-image: linear-gradient(
    179deg,
    rgb(94, 20, 223, 0.1) 1%,
    rgba(17, 19, 25, 0.05) 17%,
    rgba(17, 19, 25, 0.2) 31%,
    rgba(17, 19, 25, 0.39) 44%,
    rgba(17, 19, 25, 0.61) 56%,
    rgba(17, 19, 25, 0.8) 69%,
    rgba(17, 19, 25, 0.95) 83%,
    rgb(0, 0, 0) 99%
  );
  /* filter: blur(50px); */
  /* z-index: 1; */
  /* z-index: -1; */
}
.burn {
  position: absolute;
  width: 100vw;
  height: 302px;
  background-color: #5e14df;
  /* background-color: #fff; */
  margin-top: -220px;
  right: 0;
  margin-right: -50vw;

  filter: blur(180px);
}
.burn3 {
  position: absolute;
  width: 302px;
  height: 302px;
  background-color: #5e14df;
  /* background-color: #fff; */
  bottom: 0;
  right: 0;
  margin-right: -150px;

  filter: blur(180px);
}
.burn4 {
  position: absolute;
  width: 302px;
  height: 302px;
  background-color: #5e14df;
  /* background-color: #fff; */
  bottom: 0;
  left: 0;
  margin-left: -150px;

  filter: blur(180px);
}
.burn2 {
  position: absolute;
  width: 100vw;
  height: 302px;
  background-color: #5e14df;
  /* background-color: #fff; */
  margin-top: -220px;
  left: 0;
  margin-left: -50vw;

  filter: blur(180px);
}
.flex_b {
  display: flex;
  justify-content: space-between;
}
.g4_5 {
  display: grid;
  gap: 4px;
  width: 50%;
}
.fontim {
  font-family: zihunlaozhaopai_trial;
  font-size: 18px;
  font-weight: 400;
  line-height: 20.8px;
  text-align: left;
  padding-left: 2px;
}
.getbtn {
  background: rgb(104, 34, 227);
  border: 0.5px solid rgba(255, 255, 255, 0.12);
  border-radius: 8px;
  height: 33px;
  width: 80px;

  display: flex;
  justify-content: center;
  align-items: center;

  color: #fff;
}
.nodebtn {
  background-color: rgb(104, 34, 227);
  border: 0.5px solid rgba(255, 255, 255, 0.12);
  color: #fff;
  width: 60%;

  .ant-btn-primary:hover {
    background-color: rgb(104, 34, 227) !important;
    border-color: rgb(104, 34, 227) !important;
  }
}
:where(.css-dev-only-do-not-override-ysh7bi).ant-btn-variant-outlined:not(
    :disabled
  ):not(.ant-btn-disabled):hover {
  background-color: rgb(104, 34, 227) !important;
}
.invite {
  width: 100%;
  margin: 0 16px;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.inviteburn {
  /* Ellipse 2597 */

  position: absolute;
  width: 120px;
  height: 120px;
  left: 294px;
  top: 160px;

  background: #ffe800;
  filter: blur(100px);
}
.invh1 {
  font-family: zihunlaozhaopai_trial;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.6px;
  text-align: left;
  margin-top: 20px;
  margin-left: 16px;
}
.invbottom {
  margin: 10px 0;
  border-radius: 12px;

  font-family: TangXianBinSong;
  font-size: 14px;
  font-weight: 400;
  min-height: 300px;
  letter-spacing: 0.10000000149011612px;
  text-align: left;
  background-image: (
      95.31deg,
      rgba(0, 0, 0, 0.8) -3.76%,
      rgba(0, 0, 0, 0.7) 98.27%
    ),
    linear-gradient(102.48deg, #6854e8 49.73%, rgba(173, 172, 255, 0.3) 116.4%);
  border: 1px solid rgb(104 84 232/1);
}
.invbtop {
  display: flex;
  align-items: center;
  height: 28px;
  line-height: 20px;
  padding: 10px 16px;
}
.op6 {
  opacity: 0.8;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 100%;
}
.invbbom {
  line-height: 16px;
  margin-top: 10px;
  padding-left: 1rem;
  padding-right: 1rem;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
}
.noimg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 260px;
}

.invitetop {
  background: linear-gradient(rgb(49, 52, 252, 0.8) 0%, rgb(49, 52, 252) 100%);
  padding: 80px 16px 120px 16px;
  background-image: url(/public/img/home/homebg.png);

  img {
    width: 100%;
    border-radius: 20px;
  }
}
