.homecar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 30px;
}
.font1 {
  font-family: zihunlaozhaopai_trial;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  font-weight: 600;
}
.urlbg {
  font-family: zihunlaozhaopai_trial;
  font-size: 14px;
  font-weight: 400;

  text-align: center;

  text-align: center;
  background-color: rgb(68 10 169);
  height: 50px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  border-radius: 12px;
  border-left: 1px solid #00000033;
  border-bottom: 1px solid #00000033;
}

.centerbt {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.homebt0 {
  font-family: zihunlaozhaopai_trial;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: center;
  color: #000;
  width: 40%;
  display: flex;
  align-items: center;
  height: 41px;
  border: 1px solid #000000;
  border-radius: 100px;
  justify-content: center;
}
.homebt1 {
  font-family: zihunlaozhaopai_trial;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: center;
  background: linear-gradient(90deg, #403848 0%, #35313e 100%);
  color: #fff;
  width: 40%;
  display: flex;
  align-items: center;
  height: 41px;
  border: 0.5px solid #ffffff1f;
  border-radius: 100px;
  justify-content: center;
}
.hometopbar {
  display: grid;
  gap: 16px;
  background: linear-gradient(339.91deg, #702ce6 0.95%, #590ddc 99.28%);
  background-color: #fff;
  border: 1px solid rgb(104 84 232/1);
  margin-top: 10px;
  padding: 16px 20px;
  border-radius: 12px;
}
.homeburn {
  /* Ellipse 2596 */

  position: absolute;
  width: 229px;
  height: 29px;

  top: 243px;

  background: #d4fd48;
  filter: blur(50px);
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.img {
  display: block;
  width: 95%;
  margin: 0 auto;
}
.inputbg {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: 999;
}
.inputbar {
  position: absolute;
  top: 56px;
  left: 50%;
  width: 84%;
  transform: translateX(-50%);
}
.inputtop {
  height: 69px;
  display: grid;
  gap: 8px;
}

.inputnum {
  display: flex;
  align-items: center;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 600;
  line-height: 16px;
  text-align: center;

  border-radius: 12px;
  border: 1px solid #fff;
  height: 44px;
  padding: 0 10px;
}
